.header {
   background: linear-gradient(93deg, #00E0FF 0.98%, #FF00B8 104.55%);
}

.header.main {
   background: url('./assets/header-background.jpg');
   background-size: cover;
}

@media (max-width: 600px) {
   .header {
      padding: 0 10px;
   }
}